/* -------------------------------------------------------------------------- */
/* Slideshow */
/* -------------------------------------------------------------------------- */
#slideshow {
	height: 444px;
	position: relative; 
	overflow: hidden;
}
#slides { 
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	list-style-type: none;
}
#slides .slide {
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	   -moz-transition: all 800ms ease;
	     -o-transition: all 800ms ease;
	        transition: all 800ms ease;
}
#slides .slide.active {
	opacity: 1;
	-webkit-transition: all 800ms ease;
	   -moz-transition: all 800ms ease;
	     -o-transition: all 800ms ease;
	        transition: all 800ms ease;
}
#slides .slide .slideOverlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	opacity: 0%;
}
#slideText {
	position: absolute;
	top: 18px;
	left: 5%;
	min-width: 40%;
	max-width: 45%;
/*	background-color: rgb(255,255,255);
	background-color: rgba(255,255,255,0.85);*/
	padding: 9px 18px;
	-webkit-animation-name: fadeInRight; 
    animation-name: fadeInRight; 
	 -webkit-animation-duration: 1s; 
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
	-webkit-animation-delay: .5s; /* Chrome, Safari, Opera */
    animation-delay: .5s;
}

@-webkit-keyframes fadeInRight { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateX(20px); 
    } 
    100% { 
        opacity: 1; 
        -webkit-transform: translateX(0); 
    } 
} 
@keyframes fadeInRight { 
    0% { 
        opacity: 0; 
        transform: translateX(20px); 
    } 
    100% { 
        opacity: 1; 
        transform: translateX(0); 
    } 
} 
#slideHeading {
	line-height: 34px;
	font-size: 29px;
	letter-spacing: .055em;
}
#slideImageText {
	line-height: 28px;
	font-size: 14px;
}
#slide-nav { 
	position: absolute; 
	bottom: 28px; 
	left: 6.6%; 
}
#slide-nav .radio-wrap {
	background-color: transparent;
	background-color: rgba(0,0,0,0.15);
	border: solid 2px $color_white;
	border-radius: 100%;
	margin-right: 7px;
	width: 15px;
	height: 15px;
	cursor: pointer;
	display: inline-block;
	box-sizing: border-box;
	-webkit-transition: all 200ms ease;
	   -moz-transition: all 200ms ease;
	     -o-transition: all 200ms ease;
	        transition: all 200ms ease;
}
#slide-nav .radio-wrap:hover {
	background: $color_turquoise;
}
#slide-nav .radio-wrap.checked {
	background: $color_white;
	border: solid 2px $color_white;
}
#slide-nav .radio-wrap.checked:hover {
	background: $color_turquoise;
}
.slide-title{
	font-weight: 200;
	font-size:35px;
	color:$color_black;
	padding:34px 290px 0 54px;
	width:53%;
	line-height: 50px;
	position: absolute;
	top:196px;
	max-width:700px;
/*	text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.53);*/
}
