@media (min-width: 601px){
/*	nav ul ul{
		display: none;
		position: absolute;
		top: 0%;


		width: 283px;
		a{
			padding: 0px 0px;
		}
	}
	nav ul li:hover>ul {
		display: block;

		  left: 35px;
		    top: -45px;
	}*/
nav ul ul{
	position: absolute;
	float:none;
	margin-left:24px;
	width:285px;
	max-height:0;
	overflow:hidden;
	opacity:0;
	transition: all .2s ease-out;
	-webkit-transition: all .2s ease-out;
}
nav ul li:hover ul{
	max-height:999px;
	opacity:1;
	transition: all .2s ease-in;
	-webkit-transition: all .2s ease-in;
}
nav ul ul li{
	display:block;
	float:none;
	margin-bottom:4px;
	width:100%;
	text-align: left;
	color: $color_white;
	height: 43px;
}
nav ul li ul a{
	background:$color-green;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	width:100%;
	display:block;
	box-sizing: border-box;
	line-height: 15px;
	color:$color_white !important;
	font-size: 15px;
	padding: 14px 35px;
}
nav ul li ul a:hover{
	color:$color_green !important;
	background:#fff;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
nav ul li ul a:before{display: none;}
}
.active ul.dropdown-links li a{
	color: $color_white;
}

@media all and (min-width:1211px){
	header{width:100%;}
}
@media all and (max-width:1210px){
	header{
		left:60px;
		right:60px;
	}
	body{
		padding:0 60px;
	}
}
@media all and (max-width:1175px){
	.section-title{font-size:29px;}
	.slide-title{font-size:44px;}
}
@media all and (max-width:1110px){
	.right-side{width:58%;}
}
@media all and (max-width:1100px){
	.slide-title{font-size:42px;}
}
@media all and (max-width:1085px){
	.section-title{font-size:27px;}
}
@media all and (min-width:1061px){
	.show-thank .thank{ width:335px; }
	#newsletter-form .thank{padding:38px 44px;}
}
@media all and (max-width:1060px){

	.call, .email{font-size:15px;}
	#contact input.contact-submit-btn{
		width:230px;
		font-size:15px;
	}
	.message-button{
		height:36px;
		line-height:36px;
		width:171px;
	}
	.test-sub{margin-bottom:10px;}
	.test-sub:nth-of-type(2){margin-bottom:75px;}
	h2{font-size:20px;}
	.interior-payroll{
		width:378px;
		left:20%;
		top:6%;
	}
	.interior-bookkeeping{
		width:303px;
		left:27%;
	}
	.interior-xero{
		width:344px;
		left:21%;
		top:11%;
	}
	.header-logo{
		width:314px;
		height:64px;
		  top: 35px;
	}
	body, #cta input{ font-size:15px; }
	#cta input{
		height:27px;
		line-height: 27px;
		padding-left:14px;
	}
	#chat{top:330px;}
	nav li, footer p, .newsletter{font-size:16px;}
	.slide-title{
		padding:20px 0 0 50px;
		line-height:39px;
		font-size:35px;
		top:167px;
	}
	.inner-section-title{font-size:20px;}
	::-webkit-input-placeholder { font-size:15px; }
	:-moz-placeholder {
		font-size:15px;
		opacity:  1;
	}
	::-moz-placeholder {
		font-size:15px;
		opacity:  1;
	}
	:-ms-input-placeholder { font-size:15px; }
	.section-title{
		height:58px;
		line-height:58px;
		padding-left:52px;
		font-size:25px;
	}
	a.learn-more, .spam, footer ul, footer ul a{font-size:13px;}
	.footer-contact, .copyright, .wtc{font-size:12px;}
	.contact-links{margin:31px 2px 38px 0;}
	.contact-links a:first-child{margin-right:10px;}
	.contact-links a:last-child{margin-left:11px;}
	nav, nav li{height:41px;}
	nav{line-height:41px;}
/*	nav li a{padding:0 26px;}*/
	#cta, #phone, #chat{width:203px;}
	#cta{padding:22px 21px;}
	#phone{padding-left:23px;}
	.cta-subtitle{margin-bottom:20px;}
/*	#cta a{ margin-left: 10px;	}*/
	#page-wrapper{padding-top:167px;}
	header{height:167px;}
	#slideshow{height:378px;}
	section .container{padding:47px 255px 47px 52px;}
	section p, section li{
		margin-bottom:17px;
		line-height:26px;
	}
	.arrow{
		border-bottom:7px solid transparent;
		border-top:7px solid transparent;
		border-left:7px solid #fecc5b;
		top:20px;
		margin-left:-18px;
	}
	#home .text{padding-left:138px;}
	.payroll-icon{
		width:79px;
		left:64px;
	}
	.bookkeeping-icon{
		width:79px;
		left:69px;
	}
	.process-icon{
		width:91px;
		left:62px;
	}
	#googleMap{height:343px;}
	.icon-images{
		width:534px;
		margin:57px auto 50px;
	}
	.xero-icon, .bronze-icon, .twitter-icon, .facebook-icon, #xero footer .xero-icon, #process footer .xero-icon{width:75px;}
	#xero footer .xero-icon, #process footer .xero-icon{margin-right:-48px;}
	.bronze-icon{
		margin-right:26px;
	}
	.chat-icon{width:125px;}
	.footer-logo{
		margin:0 auto 18px;
		  margin-top: 50px;
	}
	footer p{
		margin-bottom:11px;
		line-height:25px;
	}
	#newsletter-form{
		padding:17px 0 15px;
		max-width:669px;
	}
	.newsletter{margin-bottom:14px;}
	#newsletter-form .news-submit-btn{width:16.9%;}
	#newsletter-form input, #newsletter-form .news-submit-btn{
		line-height: 45px;
		height:45px;
		font-size:15px;
	}
	#form-pop input, #form-pop textarea,#contact section input, #contact section textarea{font-size:15px;}
	.spam{
		line-height:21px;
		margin-bottom:19px;
	}
	footer h3, footer ul li{padding:0 14px;}
	footer ul{margin-bottom:15px;}
	.copyright, .wtc{font-size:11px;}
	.wtc-logo{width:28px;}
}
@media all and (max-width:940px){
	.right-side{width:57%;}
}
@media all and (max-width:915px){
	.section-title{font-size:23px;}
}
@media all and (max-width:900px){
	.slide-title{font-size:33px;}
}
@media all and (max-width:865px){
	.section-title{font-size:21px;}
	.slide-title{font-size:31px;}
}
@media all and (max-width:816px){
	nav ul li ul a{
	width:100%;
	line-height: 15px;
	color:$color_white !important;
	font-size: 13px;
	padding: 10px 20px;
}
nav ul ul li{
	height: 35px;
	  width: 80%;

}
nav ul ul{
	margin-left: 16px;
}
	section img{ margin-bottom: 10px;}
	.test-sub{margin-bottom:8px;}
	.test-sub:nth-of-type(2){margin-bottom:70px;}
/*	h1,h2{margin-bottom:20px;}*/
	.interior-payroll{
		width:315px;
	}
	.interior-bookkeeping, .interior-probate, .interior-conservatorships{
		width:242px;
		left:24%;
	}
	.interior-xero{
		width:276px;
		top:9%;
	}
	.show-thank .thank{ width:290px; }
	.show-thank .thank, #form-pop.show-thank .thank{padding:37px 36px;}
	#newsletter-form .thank{padding:19px 44px;}
	body, nav li, footer p, .newsletter, .spam{font-size:13px;}
	.newsletter{margin-bottom:10px;}
	.slide-title{
		padding:16px 0 0 37px;
		line-height:30px;
		font-size:26px;
		top:121px;
	}
	.cta-title, .inner-section-title{font-size:15px;}
	.cta-title{
		margin-bottom:5px;
		  line-height: 20px;

	}
	.cta-subtitle{
		margin-bottom:20px;
		letter-spacing: .01em;
		margin-left: -6px;
	}
	#cta a{
		  display: inline-block;
		  margin-left: -9px;
		  font-size: 14px;
		  padding-top: 5px;
		  padding-bottom: 5px;
	}
	#cta input, a.learn-more, .copyright, .wtc{font-size:10px;}
	#cta input{
		line-height: 22px;
		height:22px;
		margin-top:8px;
	}
	footer ul a{font-size:13px;}
	::-webkit-input-placeholder { font-size:10px; }
	:-moz-placeholder {
		font-size:10px;
		opacity:  1;
	}
	::-moz-placeholder {
		font-size:10px;
		opacity:  1;
	}
	:-ms-input-placeholder { font-size:10px; }
	.section-title{
		height:43px;
		line-height: 43px;
		padding-left:37px;
		font-size:19px;
	}
	#page-wrapper{padding-top:121px;}
	header{height:121px;}
	.contact-links{
		margin:17px 2px 29px 0;

		p{font-size: 13px;}
		a{
			font-size: 23px;
			margin-top: 7px;
		}
	}
	.header-logo{
		top:18px;
		width:314px;
		height:57px;
	}
	nav, nav li{
		line-height:30px;
		height:30px;
	}
	nav li a{padding:0 19px;}
	#cta, #phone, #chat{width:153px;}
	#phone{padding-left:15px;}
	#cta{padding:17px 16px;}
	#chat{
		font-size:11px;
		top:247px;
	}
	.chat{margin-top:14px;}
	#slideshow{height:284px;}
	section .container{padding:36px 171px 38px 37px;}
	section p, #about section li{
		margin-bottom:14px;
		line-height:19px;
	}
	#about section ul{
		  margin-left: 25px;
	}
	.arrow{
		border-bottom:6px solid transparent;
		border-top:6px solid transparent;
		border-left:6px solid #fecc5b;
		top:15px;
	}
	.payroll-icon{
		width:58px;
		left:50px;
	}
	.bookkeeping-icon{
		width:57px;
		left:53px;
	}
	.process-icon{
		width:68px;
		left:46px;
	}
	#home .text{padding-left:106px;}
	.inner-section-title{margin-bottom:11px;}
	.icon-images{
		width:408px;
		margin:25px auto 36px;
	}
	.chat-icon{width:92px;}
	.xero-icon, .bronze-icon, .twitter-icon, .facebook-icon, #xero footer .xero-icon, #process footer .xero-icon{width:56px;}
	#xero footer .xero-icon, #process footer .xero-icon{margin-right:-36px;}
	.footer-logo{

		margin:0 auto 13px;
		  margin-top: 50px;
	}
	footer p{
		line-height:19px;
		margin-bottom:4px;
	}
	#newsletter-form .news-submit-btn{
		font-size:11px;
		width:16.5%;
	}
	#newsletter-form{
		padding-bottom:11px;
		max-width:509px;
	}
	#newsletter-form input, #newsletter-form .news-submit-btn{
		height:35px;
		line-height:35px;
		font-size:10px;
	}
	#form-pop input, #form-pop textarea,#contact section input, #contact section textarea{font-size:10px;}
	.spam{
		margin-bottom:14px;
		line-height:17px;
	}
	.wtc-logo{width:24px;}
}
@media all and (max-width:805px){
	.section-title{font-size:17px;}
}
@media all and (max-width:760px){
	.section-title{font-size:15px;}
	.footer-section{ width: 160px;}
}
@media all and (max-width:715px){
	.section-title{font-size:14px;}
	.slide-title{font-size:24px;}
	.interior-payroll{left:15%;}
	.footer-section{
		font-size: 13px;
		padding-right: 2%;
		padding-left: 2%;
		  width: 155px;

	}
}
@media all and (max-width:665px){
	.slide-title{font-size:22px;}
}
@media all and (max-width:615px){
	.slide-title{font-size:20px;}
}
@media all and (max-width:600px){
	#form-pop input, #form-pop textarea,#contact section input, #contact section textarea{font-size:16px;}
	.interior-payroll{left:10%;}
	body{padding:0;}
	header{
		left:0;
		right:0;
	}
	.contact-links, header #cta, header #chat{display:none;}
	.mobile-show{display:block;}
	nav{margin-top:72px;}
	nav ul{
		width:270px;
		position: fixed;
		top:0; right:0;
		bottom:0;
		overflow:scroll;
        -webkit-transform: translate(270px, 0);
		transform: translate(270px, 0);
		-webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
		background:rgba(0,0,0,.6);
		z-index: 3;
		margin:0;
	}
	nav ul.show{
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	#hamburger{
        color:#fff;
        text-transform: uppercase;
        font-size:9px;
        position: fixed;
        top:0;
		right:0;
        letter-spacing:.08em;
        cursor:pointer;
        padding:19px 15px 44px;
        background:$color_green;
        z-index: 3;
		-webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
    }
    #hamburger:after{
        content:'';
        border-top:3px solid #fff;
        border-bottom:3px solid #fff;
        height:11px;
        width:28px;
        position: absolute;
        top:29px;
        right:16px;
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    #hamburger:before{
        content:'';
        border-top:3px solid #fff;
        height:3px;
        width:28px;
        position: absolute;
        top:36px;
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    #hamburger.open{
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
		-webkit-transform: translate(-270px, 0);
		transform: translate(-270px, 0);
    }
    #hamburger.open:before{
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
		top:40px;
    }
    #hamburger.open:after{
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-bottom:0;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        top:39px;
        right:13px;
    }
	nav a, nav h3{font-size:15px;}
    nav li.title{
        font-size:12px;
        color:#A7A9AB;
        padding:25px 0 15px 20px;
        margin-left:0;
    }
    nav li{
        color:$color_green;
        font-size:16px;
        letter-spacing: .04em;
        text-transform:uppercase;
        border-bottom:1px solid #696969;
        margin:0;
        padding-left:20px;
		position: relative;
		display:block;
		text-align: left;
		line-height:30px;
		float:none;
    }
    nav li a{
        color:$color_green;
        text-transform: uppercase;
        padding:0px;
    }
	nav li a:hover{text-decoration:none;}
    nav li:hover, nav li.active{
		border-bottom:1px solid #696969;
	}
	nav li:hover a, nav li.active a{color:$color_white;}
    nav{padding:0;background:transparent;}
    nav ul{padding-bottom:30px;}
	nav ul ul{
		width:0;
		position: fixed;
		overflow:hidden;
		-webkit-transition: all .2s ease;
        transition: all .2s ease;
		background: #231f20;
		top:0; bottom:0;
		right:0; left:auto;
		margin-bottom:0;
		z-index:2;
		padding-top:52px;
	}
	nav ul ul.show{
		width:215px;
		-webkit-transition: all .2s ease;
        transition: all .2s ease;
	}
	nav ul ul li:hover{border-bottom:1px solid #696969;}
	.menu-arrow{
		position: absolute;
		right:20px;
		bottom:12px;
	}
	.dropdown-overlay.show{
		position: fixed;
		top:0; bottom:0;
		right:0; width:270px;
		background: rgba(0,0,0,0.6);
		z-index:2;
	}
    .mobile-address{
        padding-top:10px;
        height:115px;
        line-height:25px;
    }
    .mobile-address a{padding:0;}
    .mobile-overlay{
        position: fixed;
        z-index:3;
        top:0; bottom:0;
        left:0; right:0;
        opacity:.7;
        display:none;
        background-color:#000;
    }
    .mobile-overlay.show{display:block;}
	.header-logo{
		top:18px; left:17px;
		  background: url(img/pollock-Law-Firm-web-small.png)center no-repeat;
		  width: 225px;
		  height: 46px;
	}
	nav{
		height:63px;
		line-height:63px;
		background-color: $color_green;
	}
	nav h3{
		height:30px;
		line-height:30px;
	}
	#phone{
        width:100%;
        background:$color_black;
        color:#231f20;
        text-align:center;
		font-size:27px;
		font-weight: 100;
		padding-left:0;
		display: block;
    }
    #phone a{
        color:$color_white;
		font-size:27px;
		font-weight: 500;
    }
	#phone.change{
		background:#000;
		color:#fff;
		-webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
	}
	#phone.change a{color:#97c1c9;}
	#slideshow{height:222px;}
	.slide-title{
		position: relative;
		top:0;
		width:100%;
		box-sizing: border-box;
		padding:14px 23.75% 29px 18px;
		font-size:24px;
	}
	#slideshow ul li{overflow:auto;}
	#home section.blue{background-color:transparent;}
	section .container{padding:25px 17px}
	#chat, #cta{
		width:100%;
		text-align:center;
		position: static;
	}
	#cta{
		position: relative;
		padding:41px 49px;
	}
	#chat{padding:20px 84px;}
	.show-thank .thank, #form-pop.show-thank .thank{
		position: absolute;
		top:0; left:0;
		width:100%; height:100%;
	}
	#form-pop.show{width:100%;}
	.section-title{
		height:42px;
		font-size:20px;
		text-align:center;
		padding:21px 37px;
		letter-spacing: .2em;
		line-height:13px;
	}
	.inner-section-title, .cta-title{
		font-size:27px;
	}
	.cta-title{
		margin-bottom:7px;
		letter-spacing:.15em;
	}
	.cta-subtitle{
		font-size:21px;
		letter-spacing: .02em;
		margin-bottom:10px;
	}
	#home section .text p, .chat-icon, .desktop-show{display:none;}
	#home .text{
		text-align: center;
		padding-left:0;
	}
	section.expanded .container{height:182px;}
	a.learn-more{
		position: absolute;
		bottom:36px;
		left:50%;
		margin-left:-57px;
		font-size:16px;
	}
	.payroll-icon{
		width:78px;
		left:50%;
		margin-left:-39px;
		top:70px;
	}
	.process-icon{
		width:90px;
		left:50%;
		margin-left:-45px;
		top:70px;
	}
	.bookkeeping-icon{
		width:82px;
		left:50%;
		margin-left:-41px;
		top:70px;
	}
	section p{line-height:24px;}
	#cta input{
		height:41px;
		line-height: 41px;
		padding-left:20px;
		font-size:16px;
	}
	::-webkit-input-placeholder { font-size:20px; }
	:-moz-placeholder {
		font-size:20px;
		opacity:  1;
	}
	::-moz-placeholder {
		font-size:20px;
		opacity:  1;
	}
	:-ms-input-placeholder { font-size:20px; }


	.arrow{top:20px;}
	.icon-images{
		margin:39px auto 10px;
		width:188px;
	}
	.xero-icon, .bronze-icon, .twitter-icon, .facebook-icon, #xero footer .xero-icon, #process footer .xero-icon{
		width:92px;
		margin-bottom:13px;
	}
	#xero footer .xero-icon, #process footer .xero-icon{margin-right:2px;}
	.bronze-icon, .facebook-icon{margin-right:0;}
	.footer-logo{
		width:214px;
		margin:0 auto 17px;
	}
	.footer-logo{
		  margin-top: 25px;
		  background: url(img/pollock-Law-Firm-web-small.png)center no-repeat;
		  width: 225px;
		  height: 46px;
	}
	footer p{
		font-size:16px;
		line-height:25px;
		text-align: center;
	}
	.newsletter{
		font-size:16px;
		color:#231f20;
		line-height:21px;
		width:100%;
		margin-bottom:27px;
	}
	#newsletter-form .firstName-field, #newsletter-form .lastName-field, #newsletter-form .email-field{width:100%;}
	footer{
		text-align: left;
		padding:0 17px 50px;
	}
	#newsletter-form input{
		height:45px;
		line-height:45px;
		padding:0 15px;
		margin-bottom:14px;
		font-size:15px;
	}
	#newsletter-form ::-webkit-input-placeholder {
		font-size:15px;
	}
	#newsletter-form :-moz-placeholder {
		font-size:15px;
		opacity:  1;
	}
	#newsletter-form ::-moz-placeholder {
		font-size:15px;
		opacity:  1;
	}
	#newsletter-form :-ms-input-placeholder {
		font-size:15px;
	}
	#newsletter-form .news-submit-btn{
		width:103px;
		font-size:15px;
	}
	#newsletter-form{padding-bottom:7px;}
	.pipe{display:none;}
	footer ul li{display:block;}
	footer ul, footer ul a{
		color:#6d6e71;
		font-size:13px;
	}
	.spam{
		color:#231f20;
		margin-bottom:29px;
	}
	footer h3:after{
		content:'';
		position: absolute;
		border-bottom:1px solid #6d6e71;
		width:40px;
		left:0;
		top:30px;
	}
	footer h3, footer ul li{padding:0;}
	footer h3{margin-bottom:28px;}
	footer ul li{
		padding:5px 0;
		border-right: none;
	}
	footer h3{
		border-right: none;
		margin-top: 25px;
	}
	footer ul li:nth-child(5){margin-bottom:29px;}
	.footer-contact, .footer-contact a{
		font-size:13px;
		color:#6d6e71;
		line-height:11px;
		margin-top:35px;
		font-weight:500;
	}
	.copyright{
		margin:54px 0 11px;
	}
	.test-sub{left:40%;}
	.test-sub:nth-of-type(2){margin-bottom:50px;}
	.left-side, .right-side{
		float:none;
		width:100%;
	}
	.profile-image{
		margin:30px auto;
		width:300px;
	}
	#contact section input, #contact section textarea{
		width:100%;
		box-sizing: border-box;
		margin-bottom:10px;
	}
	.wtc-logo{margin-left:0;}
	#page-wrapper{padding-top:135px;}
		nav ul ul{
		width:0;
		position: fixed;
		overflow:hidden;
		-webkit-transition: all .2s ease;
        transition: all .2s ease;
		background: #000;
		top:0; bottom:0;
		right:0; left:auto;
		margin-bottom:0;
		z-index:2;
		padding-top:52px;
	}
	.footer-section{
		  padding-left: 0;
		  text-align: left;
/*		  padding-top: 10px;*/
		  font-size: 13px;
		  display: block;

	}
	#about section ul{
		margin-left: 10px;
	}
	section img{
		  margin-bottom: 15px;
	}
	h1{
		font-size: 30px;
		padding-bottom: 20px;
	}
	nav ul ul li{
		width: 100%;
		padding-bottom: 10px;
		padding-top: 10px;
		padding-left: 5px;
	}

	nav ul li ul a{
		padding: 0px;
		vertical-align: top;
		color: $color_green !important;
	}

	nav ul li ul a:hover{
		color: $color_white !important;
	}
	.mobile-nav-arrow:after{
		content:"->";
		padding-left: 10px;
	}
	#slideText{
		width: 90%;
	}
}