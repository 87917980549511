footer, footer a{
	text-align: center;
	color:#bcbec0;
}
footer{padding-bottom:50px;}
footer p{
	margin-bottom:17px;
	font-size:21px;
	font-weight:100;
	line-height:34px;
	letter-spacing:.25em;
	text-transform: uppercase;
		color: $color_green;
}
footer ul, footer ul a{
	color:#6d6e71;
	font-size:17px;
}
footer ul{margin-bottom:20px;}
footer h3{text-transform: uppercase;}
footer h3, footer ul li{
	padding:0 19px;
	display:inline-block;
	position: relative;
	border-right:1px solid #6d6e71;
}
footer ul li:last-of-type{border-right:0;}
.footer-contact{
	margin-bottom:10px;
	font-size:16px;
	font-weight:100;
}
.copyright, .wtc{
	display:inline-block;
	font-size:14px;
	letter-spacing:.02em;
	font-weight:100;
	color: #404041;
}
.wtc-logo{
	display:inline-block;
	margin-left:5px;
	fill: $color_green;
}
.wtc{
	color:$color_green;
}
.footer-locations{
	margin-top: 60px;
	margin-bottom: 49px;
}
.footer-section{
	color: $color_turquoise;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: .05em;
	text-align: center;
	width: 180px;
	text-transform: uppercase;
	display: inline-block;
	padding-right:3.72%;
	padding-left:3.72%;
	vertical-align: text-top;
	
		a{
			display: block;
			font-size: 15px;
			color: $color_black;
			text-transform: initial;
		}
		li{
			display: block;
			font-size: 15px;
			color: $color_black;
			text-transform: initial;
			  border-right: none;
			  line-height: 24px;
			  font-weight: 100;
		}
		span{
			
		}
}
.footer-logo{
	width:314px;
	position: relative;
	margin:0 auto 23px;
	margin-top: 50px;
	background:url('img/pollock-Law-Firm-web.png')center no-repeat;

}
.footer-logo .spacer{padding-top:20.756%;}
