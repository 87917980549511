#newsletter-form{
	padding:17px 0 20px;
	max-width:880px;
	margin:0 auto;
	position: relative;
}
#newsletter-form input{
	background:#b7b7b7;
	height:60px;
	border:1px solid #b7b7b7;
	padding:0 22px;
	box-sizing: border-box;
	line-height:60px;
	float:left;
	margin-right:2px;
	font-size:20px;
	color:#000;
}
#newsletter-form input:focus {
	color:#000;
	background-color: #fff;
}
#newsletter-form input:focus::-webkit-input-placeholder{color:#000;}
#newsletter-form input:focus:-moz-input-placeholder{color:#000;}
#newsletter-form input:focus::-moz-input-placeholder{color:#000;}
#newsletter-form input:focus::-ms-input-placeholder{color:#000;}
.newsletter-submit-wrapper{display:inline-block;}
#newsletter-form .firstName-field, #newsletter-form .lastName-field, #newsletter-form .email-field{width:27.3%;}
#newsletter-form .news-submit-btn{
	width:17%;
	background:$color_green;
	cursor:pointer;
	-webkit-transition: all .2s ease;
    transition: all .2s ease;
	color:#fff;
	font-size:20px;
	font-family: "museo-sans",sans-serif;
	border:1px solid $color_green;
}
#newsletter-form .news-submit-btn:hover{
	color:$color_green;
	background:$color_white;
	-webkit-transition: all .2s ease;
    transition: all .2s ease;
}
.show-thank .thank{display:block;}
.thank{
	display:none;
	position: absolute;
	text-align: center;
	font-size:20px;
	padding:20px 10px;
	box-sizing: border-box;
}
#newsletter-form .thank{
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:$thank-color;
	color:#fff;
	padding:30px 44px;
}
#newsletter-form ::-webkit-input-placeholder {
	color:	#fff;
}
#newsletter-form :-moz-placeholder {
	color:	#fff;
	opacity:  1;
}
#newsletter-form ::-moz-placeholder {
	color:	#fff;
	opacity:  1;
}
#newsletter-form :-ms-input-placeholder {
	color:	#fff;
}
wtc-event-source{position: relative;}
.show-thank .thank, #form-pop.show-thank .thank{
	display:block;
	position: absolute;
	right:0;
	font-size:25px;
	font-weight: 100;
	z-index: 1;
	background:$thank-color;
	padding:54px 44px;
	text-align: left;
	text-transform: none;
	color: #fff;
}
.show-thank .thank{
	top:100%;
	width:313px;
}
#form-pop.show-thank .thank, #contact-form.show-thank .thank{
	top:0;
	left:0;
	bottom:0;
	width:100%;
	@media all and (min-width:1000px) {
			position: absolute;
			top:-68px;
			left:27%;
			bottom: 70%;
			z-index:0;
			width: 583px;

	}
}
.thank-large{
	font-size:37px;
	font-weight: 300;
	display: block;
	margin-bottom: 10px;
}
.thank-sub{
	font-size: 17px;
}
.close-btn{
	position: absolute;
	right:10px;
	top:10px;
	width:19px;
	height:19px;
	background:url('img/close-btn.png')center no-repeat;
	cursor:pointer;
}
#form-pop{
	height:0;
	overflow:hidden;
	width:438px;
	background:$color_yellow;
	position: absolute;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	font-weight: 100;
	font-size:25px;
	color:#585858;
	z-index: 1;
	box-sizing: border-box;
	line-height:30px;
}
#form-pop.show{
	height:428px;
	padding:41px 56px;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
#form-pop input, #form-pop textarea{
	width:100%;
	height:35px;
	line-height: 35px;
	margin-bottom:10px;
	border:1px solid #fff;
	padding-left:15px;
	box-sizing: border-box;
	font-size:20px;
}
#form-pop input:focus, #form-pop textarea:focus {
	color:#fff;
	background-color: $color_white;
}
#form-pop input:focus::-webkit-input-placeholder, #form-pop textarea:focus::-webkit-input-placeholder{color:#fff;}
#form-pop input:focus:-moz-input-placeholder, #form-pop textarea:focus:-moz-input-placeholder{color:#fff;}
#form-pop input:focus::-moz-input-placeholder, #form-pop textarea:focus::-moz-input-placeholder{color:#fff;}
#form-pop input:focus::-ms-input-placeholder, #form-pop textarea:focus::-ms-input-placeholder{color:#fff;}
#form-pop textarea{
	height:105px;
}
#form-pop input:first-child{margin-top:22px;}
#form-pop input.free-submit-btn{
	background:$color_turquoise;
	border:0;
	color:$color_white;
	width:167px;
	font-size:17px;
	text-align: center;
	font-family: "museo-sans",sans-serif;
	padding:0;
	line-height: 35px;
	border:1px solid $color_turquoise;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	cursor:pointer;
}
#form-pop input.free-submit-btn:hover{
	color:$color_green;
	background:$color_lightTurquoise;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
.message-button, #contact input.contact-submit-btn{
	background:$color_white;
	text-transform: uppercase;
	height:40px;
	line-height:40px;
	text-align:center;
	width:294px;
	border:1px solid $color_green;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	font-family: "museo-sans",sans-serif;
	cursor: pointer;
}
#contact input.button{
	font-size:16px;
	cursor:pointer;
	border: none;
	color: $color_black;
	background:$color_white;
	text-transform: uppercase;
	height:40px;
	line-height:40px;
	text-align:center;
	width:294px;
	border:1px solid $color_green;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	font-family: "museo-sans",sans-serif;
}
.message-button:hover, #contact input.contact-submit-btn:hover, #about section a.message-button:hover{
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	color:$color_white;
	background:$color_green;
}
#about section a.message-button{
	color:#414042;
	text-decoration:none;
}
#contact section input, #contact section textarea{
	background:#e6e7e8;
	width:60%;
	margin-bottom:15px;
	display:block;
	border:1px solid #e6e7e8;
	height:34px;
	line-height:34px;
	padding:0 15px;
	font-size:15px;
}
#contact section textarea{
	width:85%;
	height:185px;
}
#contact section input:focus, #contact section textarea:focus {
	color:#000;
	background-color: $color_white;
}
#contact section input:focus::-webkit-input-placeholder, #contact section textarea:focus::-webkit-input-placeholder{color:#000;}
#contact section input:focus:-moz-input-placeholder, #contact section textarea:focus::-moz-input-placeholder{color:#000;}
#contact section input:focus::-moz-input-placeholder, #contact section textarea:focus:-moz-input-placeholder{color:#000;}
#contact section input:focus::-ms-input-placeholder, #contact section textarea:focus::-ms-input-placeholder{color:#000;}
#contact-form{
	position: relative;
}
.newsletter{
	font-size:21px;
	margin-bottom:17px;
	color: #bcbec0;
}
.spam{
	font-size:17px;
	font-weight: 100;
	margin-bottom:24px;
	line-height:20px;
	letter-spacing:.01em;
}