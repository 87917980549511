a{
	text-decoration:none;
	color:$color_green;
}
a:hover{
	text-decoration:underline;
}
h1{
	font-size: 37px;
	line-height: 47px;
	color: $color_green;
	font-weight: 100;
/*	padding-bottom: 42px;*/
}
h2{
	text-transform:uppercase;
	font-size:25px;
	margin-bottom:30px;
	letter-spacing: .1em;
}
body{
	font-family: "museo-sans",sans-serif;
	font-size:16px;
	font-weight: 400;
	color:#5c5c5c;
	fill:currentColor;
	padding: 0 60px;
}
#page-wrapper{
	max-width:1150px;
	margin:0 auto;
	padding-top:196px;
}
.mobile-show, .thank{display:none;}