
#googleMap{height:453px;}
#googleMap img{
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
}
#googleInfo{
    font-size:15px;
    color:#231f20;
    line-height:23px;
    text-align:center;
    padding:10px 0px;
	width:150px;
}
#googleInfo span{display:block;}
#googleInfo a{
    text-decoration:none;
    display:block;
    text-transform:uppercase;
    font-weight:700;
    font-size:15px;
    color:$color_green;
    margin-top:10px;
}
section{ position: relative; }
#home section:nth-child(2n+1){ background:#f1f2f2; }
#home section:nth-child(2n){ background:#e6e7e8; }
#home section.green{ 
	background:$color_green;
	p{
		color: $color_white;
	}
}
#home section.white{ 
	background:$color_white;
	p{
		color: $color_green;
	}
}
.expand{
	color: $color_grayText;
}
#about section{
	ul{
		list-style: none;
		margin-left: 0;
		padding-left: 0;
		/*		margin-left: 30px;*/
	}
	li{
		list-style-type:none;
		margin-bottom:15px;
		line-height: 20px;
		letter-spacing:.03em;
		padding-left: 1em;
		text-indent: -1.5em;
	}
	li:before{
		content: "-";
		margin-right: 10px;
		padding-right: 5px;
	}
}
section {
	.container{
		padding:62px 305px 62px 70px;
		overflow:hidden;
		max-width:800px;
		margin:0 auto;
	}
	.title{
		font-size: 20px;
		text-transform: uppercase;
		line-height: 31px;
		letter-spacing: .125em;
		width: 65%;
		margin-bottom: 25px;
		margin-top: 50px;
	}
	p{
		margin-bottom:26px;
		line-height: 34px;
		letter-spacing:.03em;
		text-align: left;
	}
	img{
		width: 31.5%;
		float: left;
		margin-right: 6.5%;
		margin-bottom: 40px;
	}
	.call{
		font-size: 20px;
		text-transform: uppercase;
		line-height: 31px;
		letter-spacing: .024em;
	}
	ul{
		margin-left: 50px;
	}
	li{
		list-style-type:decimal;
		margin-bottom:26px;
		line-height: 34px;
		letter-spacing:.03em;
	}
}
section.testimonial{ 
	background:$color_green;
	display:none;
	p{
		color: $color_white;
	}
}
#active{
	display: block;
}
#home section.white{ 
	background:$color_white;
	p{
		color: $color_green;
	}
}

section p:last-child{ margin-bottom:0; }
.section-title{
	font-size:33px;
	height:75px;
	line-height: 75px;
	text-transform: uppercase;
	padding-left:70px;
	color:#fff;
	background:#414042;
	position: relative;
	letter-spacing: .29em;
	cursor:pointer;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
.section-title:hover{ 
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	color:#fecc5b; 
}
.section-title + section{
	height:0;
	overflow:hidden;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
.section-title + section.expanded{
	height:auto;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}

svg{
	width:100%;
	height:100%;
}
.svg-content {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
}
#home .text{ padding-left:182px; }
.inner-section-title{
	font-size:26px;
	margin-bottom:15px;
	letter-spacing:.02em;
}
a.learn-more{
	color:$color_green;
	text-transform: uppercase;
	text-decoration:underline;
	font-size:17px;
	letter-spacing:.09em;
}
a.learn-more:hover{ text-decoration:none; }


.payroll-icon{
	width:105px;
	position: absolute;
	left:86px;
}
.payroll-icon .spacer{padding-top:110.125%;}
.bookkeeping-icon{
	width:103px;
	position: absolute;
	left:90px;
}
.bookkeeping-icon .spacer{padding-top:107.023%;}
.process-icon{
	width:120px;
	position: absolute;
	left:81px;
}
.process-icon .spacer{padding-top:98.458%;}
.xero-icon, .bronze-icon, .twitter-icon, .facebook-icon{
	width:100px;
	position: relative;
	display: inline-block;
	fill:currentColor;
}
.xero-icon .spacer, .bronze-icon .spacer, .twitter-icon .spacer, .facebook-icon .spacer{padding-top:100%;}
.chat-icon{
	width:162px;
	position: relative;
	display: inline-block;
	cursor:pointer;
}
.bronze-icon{margin-right:35px;}
.facebook-icon{margin-right:15px;}
.facebook-icon a svg use{
	fill:#dcdddf;
}
.facebook-icon:hover a svg use{
	fill:#3fc4e1;
}
.twitter-icon a svg use{
	fill:#dcdddf;
}
.twitter-icon:hover a svg use{
	fill:#3fc4e1;
}
.chat-icon .spacer{padding-top:60.663%;}

.wtc-logo{
	width:33px;
	position: relative;
}
.wtc-logo .spacer{padding-top:42.215%;}
.icon-images{
	width:700px;
	margin:77px auto 63px;
}
.arrow{
	width: 0; 
	height: 0; 
	border-bottom: 9px solid transparent;  
	border-top: 9px solid transparent; 
	border-left: 9px solid #fecc5b; 
	font-size: 0;
	position: absolute;
	margin-left:-23px;
	top:27px;
}
ul.body-list li{
	margin-left:12px;
	padding-bottom:10px;
}
ul.body-list li:before{
	content:' - ';
}
#about ul.body-list li{margin-left:0;}
.interior-payroll{
	position: absolute;
	width:506px;
	z-index: -1;
	left:15%;
	top:5%;
}
.interior-payroll .spacer{padding-top:110.135%;}
.interior-bookkeeping{
	position: absolute;
	width:446px;
	z-index: -1;
	left:22%;
	top:5%;
}
.interior-bookkeeping .spacer{padding-top:106.113%;}
.interior-probate{
	position: absolute;
	width:550px;
	z-index: -1;
	left:22%;
	top:5%;
}
.interior-probate .spacer{padding-top:98.47%;}
.interior-business{
	position: absolute;
	width:470px;
	z-index: -1;
	left:18%;
	top:5%;
}
.interior-business .spacer{padding-top:100%;}
#process section:nth-child(3){background:#fecc5b;}
#xero .xero-icon{width:90px;}
#process section a{
	text-decoration:underline;
	color:#3fc4e1;
}
#process section a:hover{text-decoration:none;}
.test-sub{margin:0 0 15px 50%;}
.test-sub:nth-of-type(2){margin-bottom:88px;}
.profile-image{
	width:90%;
	background:url('img/AdamPollock.jpg')center no-repeat;
	background-size:cover;
	margin:0 50px 50px 0;
}
.profile-image .spacer{padding-top:149.925%;}
/*------------------------------ End Interior Pages ----------------------------------*/



.right-side{
	width:59%;
	float:right;
}
.left-side{
	width:41%;
	float:left;
}
#about .left-side ul{margin-bottom:25px;}
#about section a{
	color:#3fc4e1;
	text-decoration:underline;
	display:block;
	margin-bottom:10px;
}

.call{
	font-size:25px;
	margin:40px 0 25px;
}
.call a{color:#3fc4e1;}
.call a:hover{text-decoration: none;}
.email{
	font-size:25px;
	font-weight:100;
}

.livechat_button, .livechat_button img{width:100%;}
.clearIt{clear:both;}
#contact h1{
	padding-bottom: 42px;
}

p.solicitations{
	margin-bottom:17px;
	font-size:21px;
	font-weight:100;
	line-height:34px;
	letter-spacing:.25em;
	text-transform: uppercase;
	color: $color_green;
	@media all and (max-width:816px){
		font-size:13px;
		line-height:19px;
		margin-bottom:4px;
	}
	@media all and (max-width:600px){
		font-size:16px;
		line-height:25px;
		text-align: center;
	}
}