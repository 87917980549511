header{
	height:196px;
	position: fixed;
	top:0;
	max-width:1150px;
	z-index: 1;
	background:#fff;
}
.contact-links{
	float:right;
	margin:50px 2px 38px 0;
	font-weight: 100;
	font-size:16px;
	a{
		font-size: 27px;
		line-height: 25px;
		letter-spacing: .125em;
		color: $color_green;
		float: right;
		margin-top: 16px;
		transition: all .3s ease;
	-webkit-transition: all .3s ease;
	}
}
/*.contact-links a:first-child{margin-right:16px;}
.contact-links a:last-child{margin-left:14px;}*/
.contact-links a:hover, .contact-links a.active{
	color:$color_gray;
	transition: all .3s ease;
	-webkit-transition: all .3s ease;
}
nav li{
	float: left;
	height:52px;
	font-size:21px;
	text-align: center;
	transition: all .3s ease;
	-webkit-transition: all .3s ease;
}
nav li:hover, nav li.active{
	background:$color_black;
	transition: all .3s ease;
	-webkit-transition: all .3s ease;
}
nav li:hover a, nav li.active a{
	color:$color_green;
	text-decoration:none;
	transition: all .3s ease;
	-webkit-transition: all .3s ease;
}

nav{
	background:$nav;
	line-height: 52px;
	a{
		padding:14px 35px;
		letter-spacing: .03em;
		transition: all .3s ease;
		-webkit-transition: all .3s ease;
		color: $nav-links;
	}
}

#phone{
	color:#fff;
	background:#231f20;
	padding-left:29px;
	letter-spacing: .03em;
	display: none;
}
#phone a{color:#3fc4e1;}
#home #cta{ background:rgba(255,255,255,.75); }
#cta{ background:rgba(125,170,97,.75); }

#cta{ padding:32px 27px; }
#cta, #phone, #chat{

	box-sizing: border-box;
	position: absolute;
	right:0;
}
#phone, #chat{ width:265px;}
@-webkit-keyframes pulse {
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.1); }
    100% { -webkit-transform: scale(1); }
}
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

#cta {
	width: 201px;
	a{
		transition: all .2s ease;
		-webkit-transition: all .2s ease;
		background:$color_white;
		border:1px solid $color_green;
		text-align: center;
		text-transform: uppercase;
		color: $color_black;
		width: 80%;
		font-size: 15px;
		padding-left: 20px;
		padding-right: 20px;
		cursor: pointer;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-left: -4px;
		display: inline-block;
		-webkit-box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.38);
		-moz-box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.38);
		box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.38);
		-webkit-animation-duration: 2s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count:infinite;
    -webkit-animation-iteration-count:infinite;
	-webkit-animation-name: pulse;
    animation-name: pulse;

	}
	a:hover{
		color: $color_white;
		text-decoration: none;
		background:$color_green;
	}
}
#cta input{
	border:1px solid #fff;
	width:100%;
	height:37px;
	line-height: 37px;
	margin-top:9px;
	text-align:left;
	padding-left:19px;
	box-sizing: border-box;
	font-family: "museo-sans",sans-serif;
	font-size:20px;
	font-weight: 500;
	color:#414042;
}
// #cta input:focus {
// 	color:#000;
// 	background-color: #414042;
// }
// #cta input:focus::-webkit-input-placeholder{color:#fff;}
// #cta input:focus:-moz-input-placeholder{color:#fff;}
// #cta input:focus::-moz-input-placeholder{color:#fff;}
// #cta input:focus::-ms-input-placeholder{color:#fff;}
#cta input:last-child{
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	background:$color_white;
	border:1px solid $color_green;
	text-align: center;
	text-transform: uppercase;
}
#cta input:last-child:hover{
	cursor:pointer;
	background:$color_white;
	color:$color_green;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
.cta-title{
	font-size:13px;
	line-height: 27px;
	text-transform: uppercase;
	letter-spacing: .125em;
	margin-bottom:7px;
	text-align: center;
}
.cta-subtitle{
	letter-spacing: .15em;
	font-weight:300;
	margin-bottom:20px;
	font-size: 17px;
	text-align: center;
	text-transform: uppercase;
}
.dropdown-overlay.show2{
		position: fixed;
		top:0; bottom:0;
		right:0; width:270px;
		background: rgba(0,0,0,0.6);
		z-index:2;
	}
/*	nav li.dropdown:hover a{color:#fff;}*/
/*	nav li.dropdown li:hover a{color:$color_green;}*/
/*	nav ul ul{
		width:0;
		position: absolute;
		overflow:hidden;
		-webkit-transition: all .2s ease;
        transition: all .2s ease;

		top:0; bottom:0;
		right:0; left:auto;
		margin-bottom:0;
		z-index:2;
		padding-top:52px;
	}*/
	nav ul ul.show2{
		width:285px;
		-webkit-transition: all .2s ease;
        transition: all .2s ease;
	}
/*	nav li li{
		background: $color_green;
		margin-bottom: 4px;
		width: 100%;
		text-align: left;
		padding-left: 20px;
	}*/
	.header-logo{
		width:314px;
		height:65px;
		position: absolute;
		top:52px;
		left:2px;
		background:url('img/pollock-Law-Firm-web.png') center no-repeat;
	}
/*	nav ul ul li {
  display: block;
  padding-left: 10px;
  border-bottom: 1px solid #696969;
  margin-right: 0;
}*/
