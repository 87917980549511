// colors
$color_green: #7daa61;
$color_black: #000;
$color_white: #fff;
$color_gray: #a6a5a5;
$color_darkGray: #929497;
$color_grayText: #404041;
$color_yellow: #ffe300;
$color_turquoise: #00c1b2;
$color_lightTurquoise: #1bceb0;




$header-color: #fff;
$paragraph-color: #404041;
$social-icons: #fff;
$header1-color: #404041;
$header2-color: #fff;
$border-color:#404041;
$button-color:#8db3bb;
$cta-color:#8db3bb;
$nav:#000;
$nav-links: #a6a5a5;
$overlay:#8db3bb;
$section-gray:#dbdcdd;
$small-footer-color:#6d6e70;
$primary-color:#fff;
$thank-color:#7daa61;

// fonts
$font_opensans: 'Open Sans', sans-serif;

$header-font: 'Arvo', serif;
$h1-font: 'Arvo', serif;
$button-font: 'Arvo', serif;
$nav-font: 'Lato', sans-serif;
$footer-font:'Lato', sans-serif;
$body-font: 'Lato', sans-serif;

// Break Points
$tablet-break: 850px;
$large-phone-break: 750px;
$mobile-break: 600px;


@mixin input-placeholder-focus{
	color: $color_white
}


@mixin transition($sec){
-webkit-transition: all $sec ease;
transition: all $sec ease;
}
@mixin transform($vars){
-ms-transform: rotate($vars);
-webkit-transform: rotate($vars);
transform: rotate($vars);
}
// end variables

@import 'reset';
@import 'header';
@import 'general';
@import 'slideshow';
@import 'main';
@import 'forms';
@import 'footer';
@import 'responsive';